<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('jegl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('addrole')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <el-form ref="form" :model="form" label-width="160px">
            <el-form-item :label="$t('rolename')">
                <el-input v-model="form.name" style="width:200px;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('roleLe')">
                <el-input-number v-model="form.level" controls-position="right" :min="1" :max="999"></el-input-number>
            </el-form-item>
            <el-form-item :label="$t('data')">
                <el-select v-model="form.dataScope" :placeholder="$t('qxz')">
                    <el-option :label="$t('quanbu')" value="全部"></el-option>
                    <el-option :label="$t('benji')" value="本级"></el-option>
                    <el-option :label="$t('zidy')" value="自定义"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('data')" v-if="form.dataScope=='自定义'">
                <el-cascader
                v-model="form.depts"
                lazy
                :props="props"
                collapse-tags
                :show-all-levels="false"
                clearable></el-cascader>
            </el-form-item>
            <el-form-item :label="$t('rolems')">
                <el-input type="textarea" v-model="form.description" :autosize="{ minRows: 2, maxRows: 4}"  style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t('tianj')}}</el-button>
            </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            form:{
                name:"",
                level:3,
                dataScope:"全部",
                depts:"",
                description :""
            },
            depts:[],
            ids:[]
        }
    },
    computed: {
        props() {
            return {
                lazy:true,
                label:"name",
                value:"id",
                multiple: true, 
                checkStrictly: true,
                lazyLoad: this.getchild
            }
        },
    },

    created(){
        this.getdepts()
    },
    methods:{
        getdepts(){
            _api.get(apiurl.dept,{enabled: true }).then(res=>{
                this.depts=res.content
                
            })
        },
        onSubmit(){//添加
            if(this.form.dataScope=="自定义"){
                if(this.form.depts.length<=0){//判断是不是选了数据范围
                    this.$notify.error({
                        title:this.$t('error'),
                        message:this.$t('qxzsjfw')
                    })
                    return
                }else{
                    if(this.form.depts[0].id!='' && this.form.depts[0].id!=null){
                        this.form.depts=[]
                        this.$notify.error({
                            title:this.$t('error'),
                            message:this.$t('qcxxzsjfw')
                        })
                        return
                    }
                }
                var zsarr=[]
                for(let item of this.form.depts){
                    //item=item[item.length-1]
                    zsarr.push({id:item[item.length-1]})
                }
                this.form.depts=[]
                this.form.depts=zsarr
            }else{
                this.form.depts=null
            }

            _api.post(apiurl.roles,this.form).then(res=>{
                console.log(res)
                this.$notify.success({
                    title:this.$t('success'),
                    message:this.$t('addSuccess'),
                })
                this.form.name=""
                this.form.level=3
                this.form.dataScope="全部"
                this.form.depts=""
                this.form.description=""
            }).catch(err=>{
                this.$notify.error({
                    title:this.$t('error'),
                    message:err.data.message
                })
            })
            
        },
        getchild(node, resolve){
            console.log(node)
            if(node.level==0){
                _api.get(apiurl.dept,{}).then(res=>{
                    console.log(res)
                    resolve(res.content);
                })
            }else{
                 _api.get(apiurl.dept,{pid:node.data.id}).then(res=>{
                    console.log(res)
                    resolve(res.content);
                })
            }
            
        }
    }
}
</script>